import React, { useEffect, useRef, useState } from "react"
import { Box, useMediaQuery } from "@mui/material"
import { api } from "../../api"
import { useFranchise } from "../../hooks/useFranchise"
import { ReviewItem } from "./ReviewItem"
import { OrderProduct } from "../../types/server/class/OrderProduct"
import { useDraggable } from "react-use-draggable-scroll"
import { Product } from "../../types/server/class/Product"

interface ReviewsComponentProps {
    product: Product
}

export const ReviewsComponent: React.FC<ReviewsComponentProps> = ({ product }) => {
    const isMobile = useMediaQuery("(orientation: portrait)")
    const { franchise } = useFranchise()

    const [reviews, setReviews] = useState<OrderProduct[]>([])

    const fetchReviews = async () => {
        try {
            const response = await api.get("/products/reviews", { params: { product_id: product.id, store_id: franchise?.id } })
            setReviews(response.data)
        } catch (error) {
            console.log(error)
        }
    }

    useEffect(() => {
        fetchReviews()
    }, [])

    const scrollRef = useRef<HTMLElement>() as React.MutableRefObject<HTMLInputElement>
    const { events } = useDraggable(scrollRef, { applyRubberBandEffect: true })

    return (
        <Box sx={{ flexDirection: "column" }}>
            <Box color="primary.main" sx={{ fontSize: isMobile ? "" : "1.2rem" }}>
                <h3>Avaliações: ({reviews.length})</h3>
            </Box>

            <Box
                ref={scrollRef}
                {...events}
                sx={{
                    gap: isMobile ? "5vw" : "2vw",
                    width: "100vw",
                    overflowX: "auto",
                    marginLeft: isMobile ? "-5vw" : "-10vw",
                    padding: isMobile ? "0 5vw 5vw" : "0 10vw 2vw",
                    scrollbarWidth: "none",
                }}
            >
                {!!reviews.length ? (
                    reviews.sort((a, b) => Number(b.reviewDate) - Number(a.reviewDate)).map((item) => <ReviewItem review={item} key={item.orderId} />)
                ) : (
                    <Box>Nenhuma avaliação</Box>
                )}
            </Box>
        </Box>
    )
}
