import React, { useCallback, useEffect, useState } from "react"
import { Box, Skeleton, useMediaQuery } from "@mui/material"
import { DefaultWrapper } from "../../components/DefaultWrapper"
import { useUser } from "../../hooks/useUser"
import { useArray } from "burgos-array"
import { OrderComponent } from "../../components/OrderComponent"
import { useFranchise } from "../../hooks/useFranchise"
import { useMenu } from "../../hooks/useMenu"
import { api } from "../../api"
import { BozPayOrder, Order } from "../../types/server/class/Order"

interface OrdersProps {}

const UserWrapper: React.FC<{ user: User }> = ({ user }) => {
    const isMobile = useMediaQuery("(orientation: portrait)")
    const skeletons = useArray().newArray(3)

    const { franchise } = useFranchise()

    const [orders, setOrders] = useState<Order[]>([])
    const [empty, setEmpty] = useState(false)

    const addOrders = (orders: Order[]) => {
        setOrders((value) => [...value.filter((item) => !orders.find((order) => order.id === item.id)), ...orders])
    }

    const fetchOrders = useCallback(async () => {
        if (!user) return

        try {
            const response = await api.get("/order", { params: { user_id: user.id, take: 1, offset: orders.length } })
            addOrders(response.data)
        } catch (error) {
            console.log(error)
        }
    }, [orders])

    useEffect(() => {
        fetchOrders()
    }, [user])

    return (
        <Box
            sx={{
                flexDirection: "column",
                fontSize: "1.2rem",
                color: "primary.main",
                width: "100%",
                gap: isMobile ? "5vw" : "2vw",
                paddingBottom: isMobile ? "10vw" : "5vw",
            }}
        >
            {empty ? (
                <h3 style={{ textAlign: "center" }}>Nenhum pedido encontrado</h3>
            ) : !!orders.length ? (
                <>
                    {orders
                        .sort((a, b) => b.id - a.id)
                        .map((order) => (
                            <OrderComponent order={order} viewOrder key={order.id} onVisible={fetchOrders} />
                        ))}
                </>
            ) : (
                <OrderComponent viewOrder />
            )}
        </Box>
    )
}

export const Orders: React.FC<OrdersProps> = ({}) => {
    const isMobile = useMediaQuery("(orientation: portrait)")
    const menu = useMenu()
    const { user } = useUser()

    useEffect(() => {
        if (!user) {
            menu.setRenderForm("login")
            menu.setOpen(true)
        }
    }, [])

    return (
        <DefaultWrapper>
            {user ? (
                <UserWrapper user={user} />
            ) : (
                <Box sx={{ flexDirection: "column", paddingBottom: isMobile ? "10vw" : "5vw", gap: isMobile ? "5vw" : "1vw" }}>
                    <p style={{ fontFamily: "BowlbyOneSC" }}>Faça login para visualizar seus pedidos.</p>
                </Box>
            )}
        </DefaultWrapper>
    )
}
