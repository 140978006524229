import React, { useEffect, useState } from "react"
import { Avatar, Box, Paper, Skeleton, useMediaQuery } from "@mui/material"
import { Product } from "../../types/server/class/Product"
import { Carousel } from "react-responsive-carousel"
import "../../style/carouselStyle.css"
import ImageIcon from "@mui/icons-material/Image"
import { api } from "../../api"

interface ProductGalleryProps {
    product?: Product
}

export const ProductGallery: React.FC<ProductGalleryProps> = ({ product }) => {
    const isMobile = useMediaQuery("(orientation: portrait)")

    const [galery, setGalery] = useState<string[]>([])

    const fetchGallery = async () => {
        if (!product) return
        try {
            const response = await api.get("/products/gallery", { params: { product_id: product.id } })
            setGalery(response.data)
        } catch (error) {
            console.log(error)
        }
    }

    useEffect(() => {
        fetchGallery()
    }, [product])

    return (
        <Paper
            className="galery"
            sx={{
                flexDirection: isMobile ? "" : "column",
                flex: 1,
            }}
        >
            {!!galery.length ? (
                <Carousel showThumbs={false} autoPlay autoFocus={true} infiniteLoop interval={5000} transitionTime={1000} showStatus={false}>
                    {galery.map((image, index) => (
                        <Box key={index}>
                            <Avatar
                                variant="square"
                                src={"data:image/jpeg;base64," + image}
                                alt=""
                                sx={{
                                    width: isMobile ? "" : "25vw",
                                    height: isMobile ? "" : "95%",
                                    aspectRatio: "1/1",
                                    margin: "0 auto",
                                    bgcolor: "white",
                                }}
                                imgProps={{ style: { objectFit: "contain" } }}
                            >
                                <ImageIcon color="disabled" sx={{ width: "auto", height: "auto" }} />
                            </Avatar>
                        </Box>
                    ))}
                </Carousel>
            ) : (
                <Skeleton variant="rounded" animation="wave" sx={{ height: isMobile ? "" : "25vw", width: isMobile ? "" : "auto" }} />
            )}
        </Paper>
    )
}
