import React, { useEffect, useState } from "react"
import { Carousel } from "react-responsive-carousel"
//import './style.scss';
import "react-responsive-carousel/lib/styles/carousel.min.css"
import { Box, Skeleton, useMediaQuery } from "@mui/material"
import { Banner } from "../../../types/server/class/Banner"
import { api } from "../../../api"
interface PromotionsProps {}

export const Promotions: React.FC<PromotionsProps> = ({}) => {
    const isMobile = useMediaQuery("(orientation: portrait)")

    const [loading, setLoading] = useState(true)
    const [banners, setBanners] = useState<Banner[]>([])

    const fetchBanners = async () => {
        try {
            setLoading(true)
            const response = await api.get("/banner")
            console.log(response.data)
            setBanners(response.data)
        } catch (error) {
            console.log(error)
        } finally {
            setLoading(false)
        }
    }

    useEffect(() => {
        fetchBanners()
    }, [])

    return (
        <Box className="Promotions-Component" sx={{ width: "100%", flexDirection: "column", height: isMobile ? "60vw" : "25vw" }}>
            {loading ? (
                <Skeleton variant="rounded" animation="wave" sx={{ height: "100%" }} />
            ) : (
                <Carousel showThumbs={false} autoPlay autoFocus={true} infiniteLoop interval={5000} transitionTime={1000} showStatus={false}>
                    {banners
                        .sort((a, b) => a.position - b.position)
                        .map((banner) => (
                            <Box key={banner.id}>
                                <img src={isMobile ? banner.url_mobile : banner.url_desktop} alt="" />
                                {/* <p className="legend">{promotion.subtitle}</p> */}
                            </Box>
                        ))}
                </Carousel>
            )}
        </Box>
    )
}
