import { Dialog, CircularProgress, DialogContent, DialogContentText, DialogTitle, TextField, Box, useMediaQuery } from "@mui/material"
import Button from "@mui/material/Button"
import { Form, Formik } from "formik"
import React, { useRef, useState, useEffect } from "react"
import { useApi } from "../../hooks/useApi"
import { useLocalStorage } from "../../hooks/useLocalStorage"
import { api } from "../../api"
import { useFranchise } from "../../hooks/useFranchise"
import MaskedInput from "../MaskedInput"
import { useCepMask } from "burgos-masks"
import { useNavigate } from "react-router-dom"
import { Franchise } from "../../types/server/class/Franchise"

interface CepModalProps {
    open: boolean
    setOpen: (open: boolean) => void
}

export const CepModal: React.FC<CepModalProps> = ({ open, setOpen }) => {
    const isMobile = useMediaQuery("(orientation: portrait)")
    const _api = useApi()
    const storage = useLocalStorage()
    const franchise = useFranchise()
    const cep_mask = useCepMask()
    const input_ref = useRef<HTMLInputElement>(null)
    const navigate = useNavigate()

    const [error, setError] = useState("")
    const [cep, setCep] = useState("")
    const [address, setAddress] = useState<Address | null>(null)
    const [loading, setLoading] = useState(false)

    const getPostcode = async (lat: number, lng: number) => {
        try {
            const response = await api.get("/cep/geocoding", { params: { lat, lng } })
            setCep(response.data + " ")
        } catch (error) {
            console.log(error)
        } finally {
            setLoading(false)
        }
    }

    const getLocation = () => {
        if (navigator.geolocation) {
            setLoading(true)
            setAddress(null)
            setCep("")
            navigator.geolocation.getCurrentPosition(
                (position) => {
                    getPostcode(position.coords.latitude, position.coords.longitude)
                },
                (error) => {
                    console.log(error)
                    setError(error.message)
                    setLoading(false)
                }
            )
        } else {
            setError("Serviço de localização não disponível")
        }
    }

    const handleClose = (event: {}, reason: "backdropClick" | "escapeKeyDown") => {
        if (reason) return
        setOpen(false)
    }

    const saveFranchise = (data: Franchise) => {
        franchise.setFranchise(data)
        storage.set("franchise", data)

        setCep("")
        setAddress(null)
        setError("")
    }

    const saveAddress = async () => {
        setOpen(false)
        storage.set("address", address)
        storage.set("address:datetime", new Date().getTime())
        franchise.setCurrentAddress(address || undefined)

        const response = await api.post("/franchise", address)
        const data = response.data

        if (data) {
            saveFranchise(data)
        } else {
            try {
                const franchisor_response = await api.get("franchise/franchisor")
                const franchisor = franchisor_response.data
                saveFranchise(franchisor)
            } catch (error) {
                console.log(error)
            }
        }
    }

    const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        if (loading) return
        const value = event.target.value

        if (error) setError("")

        setCep(value)
    }

    useEffect(() => {
        if (cep.length == 10) {
            setLoading(true)
            _api.cep.get({
                data: { cep: cep },
                callback: (response: { data: any }) => {
                    if (response.data.erro) {
                        setError("CEP inválido")
                        input_ref.current?.focus()
                    } else {
                        const data: Address = response.data
                        setAddress(data)
                    }
                },
                finallyCallback: () => setLoading(false),
            })
        } else {
            setAddress(null)
        }
    }, [cep])

    // useEffect(() => {
    //     if (loading) {
    //         setAddress(null)
    //     }
    // }, [loading])

    return (
        <Dialog open={open} onClose={handleClose}>
            <DialogTitle sx={{ fontSize: isMobile ? "6vw" : "2vw", textAlign: "center" }}>Localização</DialogTitle>
            <DialogContent sx={{ flexDirection: "column", gap: isMobile ? "3vw" : "1vw" }}>
                <DialogContentText sx={{ marginBottom: isMobile ? "3vw" : "1vw" }}>
                    Digite seu CEP para encontrarmos a loja mais próxima
                </DialogContentText>
                <TextField
                    inputRef={input_ref}
                    value={cep}
                    onChange={handleChange}
                    label="CEP"
                    name="cep"
                    variant="standard"
                    autoFocus
                    InputLabelProps={{ sx: { fontSize: isMobile ? "5vw" : "1vw" } }}
                    InputProps={{
                        sx: { fontSize: isMobile ? "8vw" : "1.5vw" },
                        inputComponent: MaskedInput,
                        inputProps: { mask: cep_mask, inputMode: "numeric" },
                    }}
                    inputProps={{ sx: { textAlign: "center" } }}
                    error={!!error}
                    helperText={error}
                    FormHelperTextProps={{ sx: { fontSize: isMobile ? "4vw" : "1vw" } }}
                />
                {!loading && (
                    <Button sx={{ padding: 0, paddingTop: "0.5vw" }} onClick={getLocation}>
                        Não sei meu CEP
                    </Button>
                )}
                {loading && (
                    <Box sx={{ width: 1, justifyContent: "center", padding: 1 }}>
                        <CircularProgress color="primary" />
                    </Box>
                )}
                {address && (
                    <Box className="cep-container" style={{ flexDirection: "column", gap: isMobile ? "2vw" : "1vw" }}>
                        <p>Bairro: {address.bairro}</p>
                        <p>Logradouro: {address.logradouro}</p>
                        <p>Cidade: {address.localidade}</p>
                        <Button onClick={saveAddress} variant="contained">
                            OK
                        </Button>
                    </Box>
                )}
            </DialogContent>
        </Dialog>
    )
}
