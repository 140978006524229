import React from "react"
import { Box, Paper, Rating, Skeleton, useMediaQuery } from "@mui/material"
import { Product } from "../../types/server/class/Product"
import { DataText } from "../../components/DataText"

interface ProductTitleProps {
    product?: Product
}

export const ProductTitle: React.FC<ProductTitleProps> = ({ product }) => {
    const isMobile = useMediaQuery("(orientation: portrait)")

    return (
        <Paper
            elevation={1}
            className="title"
            sx={{
                padding: isMobile ? "3vw 5vw" : "1vw",
                flexDirection: "column",
                width: "100%",
                gap: "1vw",
            }}
        >
            <Box sx={{ flex: 1, justifyContent: "space-between", gap: "2vw" }}>
                <Box sx={{ flex: isMobile ? 0.7 : 0.9 }}>
                    {product ? (
                        <DataText
                            title="Marca:"
                            value={`${product?.brand}`}
                            titleSx={{
                                fontSize: isMobile ? "0.9rem" : "1.2rem",
                                justifyContent: "flex-start",
                                gap: isMobile ? "2vw" : "1vw",
                            }}
                            valueSx={{ fontSize: isMobile ? "0.8rem" : "1.2rem" }}
                        />
                    ) : (
                        <Skeleton variant="rounded" animation="wave" sx={{ width: "10vw", height: "1.5vw" }} />
                    )}
                </Box>
                <Box sx={{ gap: "1vw", alignItems: "center", fontSize: isMobile ? "0.8rem" : "1.2rem" }}>
                    <Rating value={product?.rating || null} sx={{ fontSize: "1.5rem" }} />
                    {product ? (
                        `${product.rating} (${product.ratingQtd})`
                    ) : (
                        <Skeleton variant="rounded" animation="wave" sx={{ width: "2.5vw", height: "1vw" }} />
                    )}
                </Box>
            </Box>
            <Box sx={{ justifyContent: "space-between", alignItems: "center" }}>
                {product ? (
                    <h3 style={{ fontSize: isMobile ? "1.2rem" : "2vw" }}>{product.name}</h3>
                ) : (
                    <Skeleton variant="rounded" animation="wave" sx={{ width: "30vw", height: "3vw" }} />
                )}
                <h3 style={{ display: "flex", alignItems: "center", gap: "0.5vw" }}>
                    código: #{product ? `${product.code}` : <Skeleton variant="rounded" animation="wave" sx={{ width: "3vw", height: "1vw" }} />}
                </h3>
            </Box>
        </Paper>
    )
}
