import React, { useCallback, useEffect, useRef, useState } from "react"
import { Box, Paper, Skeleton, useMediaQuery } from "@mui/material"
import { Product } from "../../types/server/class/Product"
import { useDraggable } from "react-use-draggable-scroll"
import { SimilarProduct } from "../Results/SimilarProduct"
import { api } from "../../api"
import { useFranchise } from "../../hooks/useFranchise"
import { ProductSkeleton } from "../Results/ProductSkeleton"

interface SimilarProductsContainerProps {
    product?: Product
}

interface SkeletonProductProps {
    isFirstSkeleton?: boolean
}

export const SkeletonProduct: React.FC<SkeletonProductProps> = ({ isFirstSkeleton }) => {
    const isMobile = useMediaQuery("(orientation: portrait)")

    return (
        <Box sx={{ gap: isMobile ? "3vw" : "1vw", width: isMobile ? "90vw" : "24vw", alignItems: "center" }}>
            <Skeleton
                animation="wave"
                variant="rounded"
                sx={{
                    height: isMobile ? "35vw" : "12vw",
                    width: isMobile ? "35vw" : "12vw",
                    borderRadius: isMobile ? "5vw" : "1vw",
                }}
            />

            <Box sx={{ width: isMobile ? "45%" : "50%", flexDirection: "column", gap: "2vw" }}>
                <Skeleton animation="wave" variant="rounded" sx={{ height: isMobile ? "5vw" : "1.5vw" }} />
                <Skeleton animation="wave" variant="rounded" sx={{ height: isMobile ? "7vw" : "2.2vw" }} />
                <Skeleton animation="wave" variant="rounded" sx={{ height: isMobile ? "9vw" : "2.4vw", borderRadius: "5vw" }} />
            </Box>
        </Box>
    )
}

export const SimilarProductsContainer: React.FC<SimilarProductsContainerProps> = ({ product }) => {
    const scrollRef = useRef<HTMLElement>() as React.MutableRefObject<HTMLInputElement>
    const { events } = useDraggable(scrollRef, { applyRubberBandEffect: true })
    const isMobile = useMediaQuery("(orientation: portrait)")
    const { franchise } = useFranchise()

    const [products, setProducts] = useState<Product[]>([])
    const [loading, setLoading] = useState(true)

    const fetchProducts = useCallback(async () => {
        if (!franchise || !product) return

        try {
            const response = await api.get("/products", {
                params: { store_id: franchise.id, search_type: "category", search_value: product.category, take: 10, skip: 0 },
            })
            setProducts(response.data)
        } catch (error) {
            console.log(error)
        } finally {
            setLoading(false)
        }
    }, [product, franchise])

    useEffect(() => {
        fetchProducts()
    }, [product])

    return (
        <Box
            ref={scrollRef}
            {...events}
            sx={{
                gap: isMobile ? "5vw" : "2vw",
                width: "100vw",
                overflowX: "auto",
                marginLeft: isMobile ? "-5vw" : "-10vw",
                padding: isMobile ? "0 5vw 5vw" : "0 10vw 5vw",
                scrollbarWidth: "none",
            }}
        >
            {loading
                ? new Array(10).fill(1).map((_, index) => (
                      <Paper
                          elevation={2}
                          sx={{
                              width: "100%",
                              gap: isMobile ? "3vw" : "1.5vw",
                              borderRadius: isMobile ? "5vw" : "2vw",
                              padding: isMobile ? "4vw" : "1vw",
                              userSelect: "none",
                          }}
                          key={index}
                      >
                          <SkeletonProduct />
                      </Paper>
                  ))
                : products.sort((a, b) => a.id - b.id).map((item) => <SimilarProduct product={item} key={item.id} />)}
        </Box>
    )
}
