import React from "react"
import { Box, Skeleton, useMediaQuery } from "@mui/material"
import { Product } from "../../types/server/class/Product"
import { DataText } from "../../components/DataText"

interface ProductDescriptionProps {
    product?: Product
}

export const ProductDescription: React.FC<ProductDescriptionProps> = ({ product }) => {
    const isMobile = useMediaQuery("(orientation: portrait)")

    return (
        <Box flexDirection={"column"} gap={isMobile ? "4vw" : "1vw"} flex={1}>
            <Box sx={{ flexDirection: "column", width: "100%", gap: "5vw", order: isMobile ? 2 : 1 }}>
                <Box
                    sx={{
                        flexDirection: "column",
                        color: "primary.main",
                    }}
                >
                    <DataText title="Descrição" value="" />
                    {product ? (
                        <pre style={{ textAlign: "start", whiteSpace: "break-spaces" }}>{product.description}</pre>
                    ) : (
                        <Box sx={{ flexDirection: "column", gap: "0.5vw" }}>
                            {new Array(5).fill(1).map((_, index) => (
                                <Skeleton key={index} variant="rounded" animation="wave" sx={{ width: "25vw", height: "1.5vw" }} />
                            ))}
                        </Box>
                    )}
                </Box>
            </Box>
        </Box>
    )
}
