import React, { useEffect, useState } from "react"
import { Box, Paper, Rating, useMediaQuery } from "@mui/material"
import { OrderProduct } from "../../types/server/class/OrderProduct"
import { User } from "../../types/server/class/User"
import { api } from "../../api"

interface ReviewItemProps {
    review: OrderProduct
}

export const ReviewItem: React.FC<ReviewItemProps> = ({ review }) => {
    const isMobile = useMediaQuery("(orientation: portrait)")

    const [user, setUser] = useState<User>()

    const fetchUser = async () => {
        try {
            const response = await api.get("/order/get-user", { params: { order_id: review.orderId } })
            setUser(response.data)
        } catch (error) {
            console.log(error)
        }
    }

    useEffect(() => {
        fetchUser()
    }, [])

    return (
        <Paper
            elevation={2}
            sx={{
                width: isMobile ? "90%" : "26vw",
                gap: isMobile ? "3vw" : "1vw",
                borderRadius: isMobile ? "5vw" : "2vw",
                padding: isMobile ? "4vw" : "1vw",
                flexDirection: "column",
                alignItems: "flex-end",
                height: isMobile ? "100%" : "15vw",
                overflowY: "auto",
                flexShrink: 0,
            }}
        >
            <Box sx={{ justifyContent: "space-between", width: "100%", display: "flex" }}>
                <Box sx={{ fontWeight: "bold", fontSize: "0.8rem" }}>
                    {new Date(Number(review.reviewDate)).toLocaleDateString("pt-br", { day: "2-digit", month: "long", year: "numeric" })}
                </Box>
                <Rating value={review.rating} sx={{ pointerEvents: "none" }} />
            </Box>

            <Box
                sx={{
                    width: "100%",
                    overflowWrap: "break-word",
                    wordBreak: "break-word",
                    marginTop: "1rem",
                }}
            >
                {review.review}
            </Box>

            <Box sx={{ fontWeight: "bold", marginTop: "auto" }}>
                {user?.name} {user?.lastname}
            </Box>
        </Paper>
    )
}
