import { Box, useMediaQuery } from "@mui/material"
import React, { useEffect, useState } from "react"
import { useColors } from "../../../hooks/useColors"
import { Newsletter } from "../Newsletter"
import { useFranchise } from "../../../hooks/useFranchise"
import { api } from "../../../api"

interface AboutProps {}

export const About: React.FC<AboutProps> = ({}) => {
    const isMobile = useMediaQuery("(orientation: portrait)")
    const colors = useColors()

    const { franchise } = useFranchise()

    const [franchiseAddress, setFranchiseAddress] = useState("")

    const h2Style = {
        fontSize: isMobile ? "6vw" : "1.5rem",
    }

    const pStyle = {
        fontSize: isMobile ? "5vw" : "1.2rem",
    }

    const containerStyle: React.CSSProperties = {
        width: "100%",
        flexDirection: "column",
    }

    const fetchFormatedAddress = async () => {
        try {
            const response = await api.get("/franchise/address", { params: { franchise_id: franchise?.id } })
            setFranchiseAddress(response.data)
        } catch (error) {
            console.log(error)
        }
    }

    useEffect(() => {
        fetchFormatedAddress()
    }, [franchise])

    return (
        <Box
            className="About-Component"
            style={{
                width: "100%",
                flexDirection: "column",
                gap: isMobile ? "5vw" : "1vw",
                color: colors.primary,
                marginTop: isMobile ? "" : "",
                justifyContent: "space-between",
            }}
        >
            <Box className="container" style={containerStyle}>
                <h2 style={h2Style}>Casa Lúdica</h2>
                <p style={pStyle}>
                    Somos uma loja de brinquedos que ama o que faz, especializada em Brinquedos Educativos, Instrumentos Musicais, Playgrounds e
                    Mobiliários, Materiais Pedagógicos, Jogos e Desafios, Espumados Babys
                </p>
            </Box>
            <Box className="container" style={containerStyle}>
                <h2 style={h2Style}>Escritório comercial</h2>
                <p style={pStyle}>{franchiseAddress}</p>
            </Box>
        </Box>
    )
}
