import React, { useEffect, useState } from "react"
import { Box, useMediaQuery } from "@mui/material"
import { Product } from "../../types/server/class/Product"
import { useNavigate } from "react-router-dom"
import { Category } from "../../types/server/class/Category"
import { useCategories } from "../../hooks/useCategories"

interface BreadcrumbsProps {
    product?: Product
}

export const ProductBreadcrumbs: React.FC<BreadcrumbsProps> = ({ product }) => {
    const isMobile = useMediaQuery("(orientation: portrait)")
    const navigate = useNavigate()
    const { categories } = useCategories()

    const [category, setCategory] = useState<Category>()

    const onCategoryClick = () => {
        navigate(`/search/category/${category?.id}`)
    }

    useEffect(() => {
        if (product) {
            setCategory(categories.find((category) => category.id == product.category))
        }
    }, [categories, product])

    return (
        <Box sx={{ gap: isMobile ? "2vw" : "1vw", color: "white", fontSize: "0.75rem" }}>
            <h3 style={{ fontFamily: "Poppins", cursor: "pointer" }} onClick={() => navigate("/")}>
                Início
            </h3>
            <h3 style={{ fontFamily: "Poppins" }}>/</h3>
            <h3
                style={{
                    fontFamily: "Poppins",
                    cursor: "pointer",
                    width: "auto",
                    overflow: "hidden",
                    textOverflow: "ellipsis",
                    whiteSpace: "nowrap",
                }}
                onClick={() => onCategoryClick()}
            >
                {category?.name}
            </h3>
            <h3 style={{ fontFamily: "Poppins" }}>/</h3>
            <h3
                style={{
                    fontFamily: "Poppins",
                    width: "auto",
                    overflow: "hidden",
                    textOverflow: "ellipsis",
                    whiteSpace: "nowrap",
                    maxWidth: isMobile ? "50vw" : "",
                }}
            >
                {product?.name}
            </h3>
        </Box>
    )
}
