import React, { useEffect, useState } from "react"
import { Box, Paper, Radio, TextField, useMediaQuery } from "@mui/material"
import { Quote, QuoteForm } from "../../types/server/frenet/Quote"
import { api } from "../../api"
import { useFranchise } from "../../hooks/useFranchise"
import { QuoteComponent } from "./QuoteComponent"
import { ShippingForm } from "../../types/server/ShippingForm"
import { CartProduct } from "../../definitions/Cart"

interface ShippingComponentProps {
    products: CartProduct[]
    onSelect?: (description: string, price: number) => void
    selected?: ShippingForm
}

export const ShippingComponent: React.FC<ShippingComponentProps> = ({ products, onSelect, selected }) => {
    const { franchise, currentAddress } = useFranchise()
    const isMobile = useMediaQuery("(orientation: portrait)")

    const [quoteList, setQuoteList] = useState<Quote[]>([])
    const [franchiseAddress, setFranchiseAddress] = useState("")
    const [loading, setLoading] = useState(true)

    const fetchShippingQuote = async () => {
        if (!currentAddress || !products.length) return
        setLoading(true)

        const data: QuoteForm = {
            franchise_id: franchise?.id || 2,
            products: products.map((item) => ({ ...item, cover: "" })),
            recipient_cep: currentAddress.cep,
        }
        try {
            const response = await api.post("/shipping", data)
            if (response.data) {
                setQuoteList(response.data)
            }
        } catch (error) {
            console.log(error)
        } finally {
            setLoading(false)
        }
    }

    const fetchFormatedAddress = async () => {
        try {
            const response = await api.get("/franchise/address", { params: { franchise_id: franchise?.id } })
            setFranchiseAddress(response.data)
        } catch (error) {
            console.log(error)
        }
    }

    useEffect(() => {
        fetchShippingQuote()
    }, [products])

    useEffect(() => {}, [quoteList])

    useEffect(() => {
        if (franchise) {
            fetchFormatedAddress()
        }
    }, [franchise])

    return (
        <Box sx={[{ flexDirection: "column", gap: isMobile ? "4vw" : "1vw" }]}>
            <Paper
                elevation={onSelect && 5}
                sx={{
                    padding: isMobile ? "4vw" : "1vw",
                    flexDirection: "column",
                    borderRadius: onSelect && (isMobile ? "4.5vw" : "2vw"),
                }}
            >
                <Box sx={{ width: "100%", alignItems: "center" }}>
                    {onSelect && <Radio checked={"Retirar na loja" == selected?.description} onChange={() => onSelect("Retirar na loja", 0)} />}
                    <QuoteComponent delivery_time={0} description={"Retirar na loja"} price={0} additional_description={franchiseAddress} />
                </Box>
                {quoteList?.map((item) => (
                    <Box key={item.ServiceCode} sx={{ width: "100%", alignItems: "center" }}>
                        {onSelect && (
                            <Radio
                                checked={item.ServiceDescription == selected?.description}
                                onChange={() => onSelect(item.ServiceDescription, Number(item.ShippingPrice))}
                                disabled={item.Error}
                            />
                        )}
                        <QuoteComponent
                            delivery_time={
                                Number(item.DeliveryTime) +
                                products.reduce((total, product) => (total + Number(product.stock) < 1 ? product.dropshipping : 0), 0)
                            }
                            description={item.ServiceDescription}
                            price={item.ShippingPrice || 0}
                            error={item.Error}
                        />
                    </Box>
                ))}
                <Box
                    sx={{
                        margin: isMobile ? "4vw" : onSelect ? "1vw" : "",
                        marginTop: "1vw",
                        borderTop: "1px solid",
                        paddingTop: "1vw",
                    }}
                >
                    Enviar para o CEP {currentAddress?.cep}
                </Box>
            </Paper>
        </Box>
    )
}
