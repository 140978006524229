import React, { useEffect, useState } from "react"
import { Box, useMediaQuery } from "@mui/material"
import { useNavigate, useParams } from "react-router-dom"
import { useFranchise } from "../hooks/useFranchise"
import { OrderComponent } from "../components/OrderComponent"
import { DefaultWrapper } from "../components/DefaultWrapper"
import { api } from "../api"
import { Order as OrderType } from "../types/server/class/Order"

interface OrderProps {}

export const Order: React.FC<OrderProps> = ({}) => {
    const isMobile = useMediaQuery("(orientation: portrait)")
    const orderId = useParams().id
    const navigate = useNavigate()

    const { franchise } = useFranchise()

    const [order, setOrder] = useState<OrderType>()

    const fetchOrder = async () => {
        try {
            const response = await api.get("/order", { params: { order_id: orderId, store_id: franchise?.id } })
            setOrder(response.data)
        } catch (error) {
            console.log(error)
        }
    }

    useEffect(() => {
        if (!orderId) {
            navigate("/")
        } else {
            fetchOrder()
        }
    }, [])

    useEffect(() => {
        window.scrollTo({ top: 0, behavior: "smooth" })
    }, [order])

    return (
        <DefaultWrapper>
            <Box sx={{ flexDirection: "column", paddingBottom: isMobile ? "10vw" : "5vw", gap: isMobile ? "5vw" : "1vw" }}>
                <Box sx={{ fontSize: "1rem", color: "primary.main" }}>
                    <h2>Seu pedido: #{order?.id}</h2>
                </Box>
                <OrderComponent order={order} />
            </Box>
        </DefaultWrapper>
    )
}
