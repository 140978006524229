export const estados = [
    { id: 1, value: "AC", label: "Acre" },
    { id: 2, value: "AL", label: "Alagoas" },
    { id: 3, value: "AP", label: "Amapá" },
    { id: 4, value: "AM", label: "Amazonas" },
    { id: 5, value: "BA", label: "Bahia" },
    { id: 6, value: "CE", label: "Ceará" },
    { id: 7, value: "DF", label: "Distrito Federal" },
    { id: 8, value: "ES", label: "Espírito Santo" },
    { id: 9, value: "GO", label: "Goiás" },
    { id: 10, value: "MA", label: "Maranhão" },
    { id: 11, value: "MT", label: "Mato Grosso" },
    { id: 12, value: "MS", label: "Mato Grosso do Sul" },
    { id: 13, value: "MG", label: "Minas Gerais" },
    { id: 14, value: "PA", label: "Pará" },
    { id: 15, value: "PB", label: "Paraíba" },
    { id: 16, value: "PR", label: "Paraná" },
    { id: 17, value: "PE", label: "Pernambuco" },
    { id: 18, value: "PI", label: "Piauí" },
    { id: 19, value: "RJ", label: "Rio de Janeiro" },
    { id: 20, value: "RN", label: "Rio Grande do Norte" },
    { id: 21, value: "RS", label: "Rio Grande do Sul" },
    { id: 22, value: "RO", label: "Rondônia" },
    { id: 23, value: "RR", label: "Roraima" },
    { id: 24, value: "SC", label: "Santa Catarina" },
    { id: 25, value: "SP", label: "São Paulo" },
    { id: 26, value: "SE", label: "Sergipe" },
    { id: 27, value: "TO", label: "Tocantins" },
]
