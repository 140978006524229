import { Skeleton, Box, Grid, useMediaQuery } from "@mui/material"
import React, { useState, useEffect, useCallback, useRef } from "react"
import { useNavigate, useParams } from "react-router-dom"
import { useColors } from "../../hooks/useColors"
import { ProductContainer } from "./ProductContainer"
import { useArray } from "burgos-array"
import { DefaultWrapper } from "../../components/DefaultWrapper"
import { useFranchise } from "../../hooks/useFranchise"
import { Product, SearchType } from "../../types/server/class/Product"
import { api } from "../../api"
import { useVisibleCallback } from "burgos-use-visible-callback"
import { ProductSkeleton } from "./ProductSkeleton"
//import "./style.scss"

interface ResultsProps {}

const take = 10

export const Results: React.FC<ResultsProps> = ({}) => {
    const isMobile = useMediaQuery("(orientation: portrait)")
    const navigate = useNavigate()
    const search_type = useParams().type as SearchType | undefined
    const search_value = useParams().value
    const colors = useColors()
    const { franchise } = useFranchise()
    
    const [products, setProducts] = useState<Product[]>([])
    const [loading, setLoading] = useState(true)
    
    const skeletons = useArray().newArray(10)

    const getProducts = useCallback(
        async (skip: number = 0) => {
            if (!franchise) return

            if (!skip) setLoading(true)

            try {
                const response = await api.get("/products", {
                    params: { store_id: franchise.id, search_type, search_value, take: take + skip, skip },
                })
                const products = response.data as Product[]
                setProducts(
                    skip ? (value) => [...value.filter((item) => !products.find((product) => product.id === item.id)), ...products] : products
                )
            } catch (error) {
                console.log(error)
            } finally {
                setLoading(false)
            }
        },
        [franchise, search_type, search_value]
    )

    useEffect(() => {
        getProducts()
    }, [search_type, search_value])

    useEffect(() => {
        if (products) setLoading(false)
    }, [products])

    useEffect(() => {
        if (!franchise) {
            navigate("/")
        }
        getProducts()
    }, [])

    return franchise ? (
        <DefaultWrapper>
            <h3 className="results-title" style={{ color: colors.primary }}>
                Resultados da pesquisa:
            </h3>

            {loading ? (
                <Grid container spacing={2} sx={{ marginBottom: "10vw" }} columns={isMobile ? 1 : 3}>
                    {skeletons.map((index) => (
                        <Grid item key={index} xs={1}>
                            <ProductSkeleton />
                        </Grid>
                    ))}
                </Grid>
            ) : (
                <Grid container spacing={2} sx={{ marginBottom: "10vw" }} columns={isMobile ? 1 : 3}>
                    {products.map((product: Product, index) => (
                        <Grid item key={product.id} xs={1}>
                            <ProductContainer
                                product={product}
                                onVisible={index === products.length - 2 ? () => getProducts(products.length) : undefined}
                            />
                        </Grid>
                    ))}
                </Grid>
            )}

            {!loading && products.length == 0 && <p style={{ alignSelf: "center", marginBottom: "10vw" }}>Nenhum resultado</p>}
        </DefaultWrapper>
    ) : null
}
