import React from "react"
import { Box, IconButton, Skeleton, useMediaQuery } from "@mui/material"
import { Product } from "../../types/server/class/Product"
import { useCart } from "../../hooks/useCart"
import { ReactComponent as ArrowIcon } from "../../images/arrow.svg"
import { useColors } from "../../hooks/useColors"
import { CurrencyText } from "../../components/CurrencyText"
import { ButtonComponent } from "../../components/ButtonComponent"
import { SoldOutForm } from "./SoldOutForm"

interface ProductCartButtonsProps {
    product?: Product
    quantity: number
    setQuantity: React.Dispatch<React.SetStateAction<number>>
}

export const ProductCartButtons: React.FC<ProductCartButtonsProps> = ({ product, quantity, setQuantity }) => {
    const isMobile = useMediaQuery("(orientation: portrait)")
    const cart = useCart()
    const colors = useColors()
    const soldout = Number(product?.stock) < 1 && Number(product?.dropshipping) < 1
    const cantAddMore = product ? quantity + 1 > (product.stock ? product.stock : 0) : false

    const changeQuantity = (value: number) => {
        if (value == -1 && quantity == 1) return

        setQuantity(quantity + value)
    }

    return (
        <Box
            sx={{
                flexDirection: "column",
                gap: isMobile ? "4vw" : "1vw",
                width: "100%",
                order: isMobile ? 1 : 2,
            }}
        >
            <Box
                className="numbers"
                sx={{
                    justifyContent: "space-between",
                }}
            >
                <Box
                    className="quantity-container"
                    sx={{
                        alignItems: "center",
                        gap: isMobile ? "1vw" : "0.5vw",
                        display: product?.stock == 0 ? "none" : "",
                        width: "fit-content",
                    }}
                >
                    <IconButton
                        onClick={() => changeQuantity(-1)}
                        disabled={quantity === 1}
                        sx={{ padding: 0, opacity: quantity === 1 ? 0.5 : undefined }}
                    >
                        <ArrowIcon />
                    </IconButton>

                    <Box
                        className="quantity"
                        sx={{
                            color: colors.primary,
                            fontWeight: "bold",
                            fontSize: isMobile ? "5vw" : "1.5rem",
                            justifyContent: "center",
                            alignItems: "center",
                            padding: isMobile ? "2vw 3vw" : "0 1vw",
                            backgroundColor: colors.background_secondary,
                            border: `1px solid ${colors.primary}`,
                            borderRadius: "1vw",
                            width: isMobile ? "12vw" : "4vw",
                        }}
                    >
                        <p style={{ userSelect: "none" }}>{quantity}</p>
                    </Box>

                    <IconButton onClick={() => changeQuantity(1)} sx={{ padding: 0, opacity: cantAddMore ? 0.5 : undefined }} disabled={cantAddMore}>
                        <ArrowIcon style={{ transform: "rotate(180deg)" }} />
                    </IconButton>
                </Box>
                <Box sx={{ flexDirection: isMobile ? "column" : "row", alignItems: "center", gap: isMobile ? 0 : "0.5vw" }}>
                    {!!product?.promotionalPrice && (
                        <Box sx={{ alignItems: "center", gap: isMobile ? "2vw" : "0.5vw" }}>
                            de:
                            <CurrencyText
                                value={product.originalPrice * quantity}
                                style={{
                                    color: colors.primary,
                                    fontSize: isMobile ? "5vw" : "1.2rem",
                                    fontWeight: "bold",
                                    textDecoration: "line-through 2px",
                                }}
                            />
                        </Box>
                    )}
                    <Box sx={{ alignItems: "center", gap: isMobile ? "2vw" : "0.5vw" }}>
                        por:
                        {product ? (
                            <CurrencyText
                                value={product.price * quantity}
                                style={{
                                    color: colors.primary,
                                    fontSize: isMobile ? "7vw" : "1.5rem",
                                    fontWeight: "bold",
                                }}
                            />
                        ) : (
                            <Skeleton variant="rounded" animation="wave" sx={{ width: "5vw", height: "2vw" }} />
                        )}
                    </Box>
                </Box>
            </Box>
            <ButtonComponent onClick={product ? () => cart.add(product, quantity) : undefined} disabled={!product || soldout}>
                {soldout ? "Esgotado" : "Adicionar ao carrinho"}
            </ButtonComponent>
            {soldout && product && <SoldOutForm product={product} />}
        </Box>
    )
}
