import React, { useEffect, useMemo, useState } from "react"
import { AlertColor, Box, Skeleton, SxProps, useMediaQuery } from "@mui/material"
// import { Order as order?.bozpay_order } from "boz.pay.component"
import { CurrencyText } from "./CurrencyText"
import { ProductComponent } from "./Checkout/Product"
import { PendingPayment } from "./PendingPayment"
import statusEnum from "../tools/enumStatus"
import { useArray } from "burgos-array"
import { useColors } from "../hooks/useColors"
import { useNavigate } from "react-router-dom"
import { FaUserAlt } from "react-icons/fa"
import { FaPhone } from "react-icons/fa6"
import { TiLocation } from "react-icons/ti"
import { ButtonComponent } from "./ButtonComponent"
import { OrderModal } from "./OrderModal"
import { api, bozpayApi } from "../api"
import { useFranchise } from "../hooks/useFranchise"
import { Order } from "../types/server/class/Order"
import { Coupon } from "../types/server/class/Coupon"
import { useVisibleCallback } from "burgos-use-visible-callback"

interface OrderComponentProps {
    order?: Order
    viewOrder?: boolean
    onVisible?: () => void
}

interface DataTextProps {
    title: string
    value: React.ReactNode
    color?: AlertColor
    bold?: boolean
}

const DataText: React.FC<DataTextProps> = ({ title, value, color, bold }) => {
    const isMobile = useMediaQuery("(orientation: portrait)")
    return (
        <Box
            sx={{
                fontSize: isMobile ? "4vw" : "1.5rem",
                alignItems: "center",
                padding: isMobile ? "1vw" : 0,
                color: "primary.main",
                fontFamily: "poppins",
                fontWeight: bold ? "bold" : "",
                gap: "1vw",
            }}
        >
            {title} <Box sx={{ fontSize: "1rem", color: `${color}.main`, fontWeight: bold ? "bold" : "" }}>{value}</Box>
        </Box>
    )
}

const RealText: React.FC<{ value: string | number }> = ({ value }) => <CurrencyText value={value} style={{ fontSize: "1rem" }} />

export const OrderComponent: React.FC<OrderComponentProps> = ({ order: initialOrder, viewOrder, onVisible }) => {
    const ref = useVisibleCallback(() => {
        if (onVisible) onVisible()
    }, {})

    const [order, setOrder] = useState(initialOrder)
    const [coupon, setCoupon] = useState<Coupon | null>(null)
    const order_status = order?.bozpay_order?.status || ""

    const isMobile = useMediaQuery("(orientation: portrait)")
    const subtotal = order?.bozpay_order?.products?.reduce((totalPrice, product) => totalPrice + product.price * product.quantity, 0) || 0
    const status = statusEnum(order_status)
    const navigate = useNavigate()

    const colors = useColors()
    const { bozpayStoreIdentifier } = useFranchise()

    const [openReviewModal, setOpenReviewModal] = useState(false)

    const maybe_delivered = order_status === "Em trânsito" || order_status === "Concluído"
    const all_reviewed = order?.products?.every((item) => item.rating !== null)

    const refreshOrder = async () => {
        if (!order) return

        try {
            const response = await api.get("/order", { params: { order_id: order.id } })
            setOrder(response.data)
        } catch (error) {
            console.log(error)
        }
    }

    const fetchCoupon = async () => {
        if (!order) return
        try {
            const response = await api.get("/coupon", { params: { coupon_id: order.coupon_id } })
            setCoupon(response.data)
        } catch (error) {
            console.log(error)
        }
    }

    useEffect(() => {
        if (order?.coupon_id) fetchCoupon()
    }, [order])

    useEffect(() => {
        setOrder(initialOrder)
    }, [initialOrder])

    return (
        <Box
            sx={{
                flexDirection: "column",
                gap: isMobile ? "2vw" : "1vw",
                border: "1px solid  gray",
                padding: isMobile ? "4vw" : "1vw",
                borderRadius: isMobile ? "4vw" : "1vw",
            }}
            ref={ref}
        >
            <Box sx={{ justifyContent: "space-between", alignItems: "center", flexDirection: "row" }}>
                {viewOrder &&
                    (order ? (
                        <h4 style={{ fontFamily: "BowlbyOneSC", fontWeight: "600", color: colors.primary }}>Pedido #{order.id}</h4>
                    ) : (
                        <Skeleton variant="rounded" animation="wave" sx={{ width: "10vw", height: "2vw" }} />
                    ))}
                {viewOrder &&
                    (order ? (
                        <p
                            style={{
                                textDecoration: "underline",
                                fontSize: isMobile ? "3.5vw" : "1rem",
                                fontFamily: "BowlbyOneSC",
                                cursor: "pointer",
                            }}
                            onClick={() => navigate(`/order/${order.id}`)}
                        >
                            Ver Pedido{" >"}
                        </p>
                    ) : (
                        <Skeleton variant="rounded" animation="wave" sx={{ width: "7vw", height: "1vw" }} />
                    ))}
            </Box>
            {order_status == "PENDING" && order && <PendingPayment orderId={order.id.toString()} />}

            <Box alignItems="center" justifyContent={"space-between"}>
                {order ? (
                    <DataText title="Status: " value={status.text} color={status.color} bold />
                ) : (
                    <Skeleton variant="rounded" animation="wave" sx={{ width: "13vw", height: "1.8vw" }} />
                )}
                {order ? (
                    <p style={{ fontSize: "0.9rem", color: colors.primary }}>{new Date(Number(order.datetime)).toLocaleDateString("pt-br")} </p>
                ) : (
                    <Skeleton variant="rounded" animation="wave" sx={{ width: "5vw", height: "1vw" }} />
                )}
            </Box>
            {maybe_delivered && !all_reviewed && (
                <ButtonComponent onClick={() => setOpenReviewModal(true)} style={{ margin: isMobile ? "5vw 0" : "1vw 0" }}>
                    {order_status === "Em trânsito" ? "Confirmar recebimento" : "Avaliar pedido"}
                </ButtonComponent>
            )}
            {!viewOrder && (
                <>
                    <p style={{ fontFamily: "BowlbyOneSC", color: "gray", fontSize: "1.0rem" }}>Endereço de entrega</p>
                    {order?.bozpay_order ? (
                        <Box
                            sx={{
                                width: 1,
                                padding: isMobile ? "3vw" : "1vw",
                                flexDirection: "column",
                                borderRadius: isMobile ? "5vw" : "1vw",
                                border: "1px solid gray",
                                height: "fit-content",
                                overflowY: "auto",
                                maxHeight: "50vw",
                                gap: isMobile ? "3vw" : "1vw",
                                alignItems: "center",
                            }}
                        >
                            <Box sx={{ gap: "2vw", alignItems: "center" }}>
                                <TiLocation color="gray" style={{ width: isMobile ? "10vw" : "2vw", height: isMobile ? "10vw" : "2vw" }} />
                                <p style={{ fontWeight: "bold", color: "rgba(85, 85, 85, 1)", fontSize: "0.85rem" }}>
                                    {order.bozpay_order.billing.address.address},{order.bozpay_order.billing.address.number} -{" "}
                                    {order.bozpay_order.billing.address.district}, {order.bozpay_order.billing.address.city} -{" "}
                                    {order.bozpay_order.billing.address.state}, {order.bozpay_order.billing.address.postcode}
                                </p>
                            </Box>
                            {/* <Box sx={{ gap: "2vw" }}>
                            <FaUserAlt color="gray" style={ { width: "4vw", height: "4vw" } } />
                          
                        </Box>
                        <Box sx={{ gap: "2vw" }}>
                            <FaPhone color="gray" style={{ width: "4vw", height: "4vw" }} />
                        </Box> */}
                        </Box>
                    ) : (
                        <Skeleton variant="rounded" animation="wave" sx={{ width: 1, height: "4vw", borderRadius: isMobile ? "5vw" : "1vw" }} />
                    )}
                </>
            )}

            <Box sx={{ flexDirection: "column", gap: isMobile ? "4vw" : "1vw", p: isMobile ? "1vw" : 0 }}>
                <p style={{ fontFamily: "BowlbyOneSC", color: "gray", fontSize: "1rem" }}>Itens ({order?.products.length})</p>
                <Box
                    sx={{
                        flexDirection: "column",
                        alignItems: "flex-start",
                        gap: isMobile ? "5vw" : "2vw",
                        padding: isMobile ? "3vw" : "1vw",
                        borderRadius: isMobile ? "5vw" : "1vw",
                        border: "1px solid gray",
                        height: "fit-content",
                    }}
                >
                    {order
                        ? order.products.map((item) => (
                              // é o mesmo componente usado no checkout, cuidado ao editar

                              <ProductComponent
                                  key={item.id}
                                  browseToItem
                                  //   @ts-ignore
                                  product={{
                                      ...item,
                                      cover: "",
                                      brand: "",
                                      sold: 0,
                                      discountPercentage: 0,
                                      originalPrice: 0,
                                      promotionalPrice: 0,
                                      rating: 0,
                                      id: Number(item.referenceId),
                                      code: "",
                                      ratingQtd: 0,
                                      dropshipping: 0,
                                  }}
                                  hideCloseButton
                                  review={{
                                      orderId: Number(order.id),
                                      rating: item.rating || 0,
                                      referenceId: Number(item.referenceId),
                                      comment: item.review || undefined,
                                  }}
                              />
                          ))
                        : new Array(2).fill(1).map((_, index) => (
                              <Box key={index} sx={{ gap: "1vw", width: 1, alignItems: "center" }}>
                                  <Skeleton
                                      variant="rounded"
                                      animation="wave"
                                      sx={{ width: isMobile ? "12vw" : "5vw", height: isMobile ? "12vw" : "5vw" }}
                                  />

                                  <Box sx={{ flexDirection: "column", gap: isMobile ? "1.8vw" : "1vw" }}>
                                      <Skeleton variant="rounded" animation="wave" sx={{ width: "30vw", height: "1.5vw" }} />
                                      <Skeleton variant="rounded" animation="wave" sx={{ width: "6vw", height: "0.8vw" }} />
                                      <Skeleton variant="rounded" animation="wave" sx={{ width: "9.5vw", height: "0.8vw" }} />
                                      <Skeleton variant="rounded" animation="wave" sx={{ width: "7.5vw", height: "0.8vw" }} />
                                  </Box>
                              </Box>
                          ))}
                </Box>
                <hr></hr>
                <Box
                    sx={{
                        flexDirection: "column",
                        alignItems: "end",
                        gap: isMobile ? "2vw" : "1vw",
                    }}
                >
                    <p style={{ fontSize: "1rem", color: "gray", display: "flex", gap: "0.5vw", alignItems: "center" }}>
                        <span style={{ fontWeight: "600" }}>Subtotal de Itens: </span>
                        {order ? (
                            <CurrencyText value={subtotal} />
                        ) : (
                            <Skeleton variant="rounded" animation="wave" sx={{ width: "3.3vw", height: "1vw" }} />
                        )}
                    </p>
                    <p style={{ fontSize: "1rem", color: "gray", display: "flex", gap: "0.5vw", alignItems: "center" }}>
                        <span style={{ fontWeight: "600" }}> Frete: </span>
                        {order ? (
                            <CurrencyText value={order.shippingPrice} />
                        ) : (
                            <Skeleton variant="rounded" animation="wave" sx={{ width: "2.8vw", height: "1vw" }} />
                        )}
                    </p>
                    {coupon && (
                        <p style={{ fontSize: "1rem", color: "gray", display: "flex", gap: "0.5vw", alignItems: "center" }}>
                            <span style={{ fontWeight: "600" }}> Cupom ({coupon.code}): </span>
                            <CurrencyText value={(coupon.is_percent ? (coupon.value / 100) * subtotal : coupon.value) * -1} />
                        </p>
                    )}
                    <p style={{ fontSize: "1rem", color: "gray", display: "flex", gap: "0.5vw", alignItems: "center" }}>
                        <span style={{ fontWeight: "600" }}> Total:</span>{" "}
                        {order ? (
                            <CurrencyText value={order.total + order.shippingPrice} />
                        ) : (
                            <Skeleton variant="rounded" animation="wave" sx={{ width: "3.3vw", height: "1vw" }} />
                        )}
                    </p>
                </Box>
            </Box>
            {order && <OrderModal order={order} externallyOpen={openReviewModal} setExternallyOpen={setOpenReviewModal} updateOrder={refreshOrder} />}
        </Box>
    )
}
