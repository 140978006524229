import React from "react"
import { Box } from "@mui/material"
import { Quote } from "../../types/server/frenet/Quote"
import { CurrencyText } from "../../components/CurrencyText"

interface QuoteComponentProps {
    description: string
    additional_description?: string
    price: string | number
    delivery_time: string | number
    error?: boolean
}

export const QuoteComponent: React.FC<QuoteComponentProps> = ({ description, price, delivery_time, error, additional_description }) => {
    return (
        <Box sx={{ flexDirection: "column", opacity: error ? 0.4 : 1, width: "100%" }}>
            <Box sx={{ fontWeight: "bold" }}>{description}</Box>
            <Box sx={{ fontSize: "0.8rem" }}>{additional_description}</Box>

            <Box sx={{ flexDirection: "row", justifyContent: "space-between", width: "100%" }}>
                {!error ? (
                    <>
                        <Box>{!!price && <CurrencyText value={price.toString().replace(".", ",")} />}</Box>
                        {!!delivery_time && <Box>{delivery_time} dia(s)</Box>}
                    </>
                ) : (
                    <Box>Indisponível</Box>
                )}
            </Box>
        </Box>
    )
}
