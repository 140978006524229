import React, { useCallback, useEffect, useLayoutEffect, useRef, useState } from "react"
import { useNavigate, useParams } from "react-router-dom"
import { Background } from "../../components/Background"
import { Header } from "../../components/Header"
import { SearchField } from "../../components/SearchField"
import { Skeleton, Paper, IconButton, Box, AlertColor, Rating, SxProps, useMediaQuery, Grid, Avatar } from "@mui/material"
//import "./style.scss"
import { useCart } from "../../hooks/useCart"
import { ButtonComponent } from "../../components/ButtonComponent"
import { Footer } from "../../components/Footer"
import useMeasure from "react-use-measure"
import { SimilarProduct } from "../Results/SimilarProduct"
import { useDraggable } from "react-use-draggable-scroll"
import { ShippingComponent } from "./ShippingComponent"
import { ReviewsComponent } from "./ReviewsComponent"
import { SoldOutForm } from "./SoldOutForm"
import { ProductBreadcrumbs } from "./ProductBreadcrumbs"
import { Product } from "../../types/server/class/Product"
import { api } from "../../api"
import { useFranchise } from "../../hooks/useFranchise"
import { ProductTitle } from "./ProductTitle"
import { DataText } from "../../components/DataText"
import { ProductGallery } from "./ProductGallery"
import { ProductDescription } from "./ProductDescription"
import { ProductCartButtons } from "./ProductCartButtons"
import { SimilarProductsContainer } from "./SimilarProductsContainer"

interface ProductProps {}

export const ProductPage: React.FC<ProductProps> = ({}) => {
    const isMobile = useMediaQuery("(orientation: portrait)")
    const navigate = useNavigate()
    const id = useParams().id as string | undefined
    const [descriptionRef, { height }] = useMeasure()
    const initialDescriptionHeight = isMobile ? "30vw" : "20vw"
    // const current_id_ref = useRef(0)
    const max_height = useRef(height)
    const { franchise } = useFranchise()

    const [loading, setLoading] = useState(true)
    const [product, setProduct] = useState<Product>()
    const [quantity, setQuantity] = useState(1)
    const [descriptionHeight, setDescriptionHeight] = useState<number | string>(0)
    const fullDescription = descriptionHeight == max_height.current

    const onDescriptionClick = () => {
        setDescriptionHeight((height) => (fullDescription ? initialDescriptionHeight : max_height.current))
    }

    const fetchProduct = useCallback(async () => {
        if (!franchise || !id) return

        try {
            const response = await api.get("/products", { params: { store_id: franchise.id, product_id: id } })
            setProduct(response.data)
        } catch (error) {
            console.log(error)
        } finally {
            setLoading(false)
        }
    }, [franchise, id])

    useEffect(() => {
        if (height > 0 && !max_height.current) {
            max_height.current = height
            setDescriptionHeight(initialDescriptionHeight)
        }
    }, [height])

    useEffect(() => {
        fetchProduct()
    }, [id])

    useEffect(() => {
        window.scrollTo({ top: 0, behavior: "smooth" })
    }, [])

    return (
        <Box
            className="Product-Page"
            sx={{
                width: "100%",
                flexDirection: "column",
                gap: isMobile ? "5vw" : "2vw",
                padding: isMobile ? "0 5vw" : "0 10vw",
                paddingBottom: isMobile ? "5vw" : 0,
            }}
        >
            <Background />
            <Header />
            {isMobile && <SearchField />}

            <ProductBreadcrumbs product={product} />

            <ProductTitle product={product} />

            <Grid container spacing={1.5} columns={isMobile ? 1 : 3}>
                <Grid item xs={1}>
                    <ProductGallery product={product} />
                </Grid>

                <Grid item xs={1}>
                    <ProductDescription product={product} />
                </Grid>

                <Grid item xs={1}>
                    <Box sx={{ flexDirection: "column", color: "primary.main", gap: isMobile ? "4vw" : "1vw" }}>
                        <DataText title="Calcule o Frete" value="" />
                        <ShippingComponent products={product ? [{ ...product, quantity }] : []} />
                        <ProductCartButtons product={product} quantity={quantity} setQuantity={setQuantity} />
                    </Box>
                </Grid>
            </Grid>
            {product && <ReviewsComponent product={product} />}

            <Box color="primary.main" sx={{ fontSize: isMobile ? "" : "1.2rem", gap: "1vw", alignItems: "flex-end" }}>
                <h3>Produtos similares</h3>
                <h3 className="link" style={{ fontSize: "1rem" }} onClick={() => navigate(`/search/category/${product?.category}`)}>
                    ver mais
                </h3>
            </Box>

            <SimilarProductsContainer product={product} />
            <Footer />
        </Box>
    )
}
