import React from "react"
import { Box, CircularProgress, Grid, Paper, useMediaQuery } from "@mui/material"
import { useCart } from "../../hooks/useCart"
import { CurrencyText } from "../CurrencyText"
import { ButtonComponent } from "../ButtonComponent"
import { Coupon } from "../../types/server/class/Coupon"

interface ReviewProps {
    handleSubmit: () => void
    makingOrder: boolean
    shipping_price: number
    coupon: Coupon | null
}

export const Review: React.FC<ReviewProps> = ({ handleSubmit, makingOrder, shipping_price, coupon }) => {
    const cart = useCart()
    const isMobile = useMediaQuery("(orientation: portrait)")

    const coupon_value = coupon?.is_percent ? cart.total * (coupon.value / 100) : coupon?.value

    const total = cart.total + shipping_price - (coupon_value || 0)

    return (
        <Box sx={{ flexDirection: "column", color: "primary.main", gap: isMobile ? "5vw" : "2vw", width: "100%" }}>
            <p style={{ fontFamily: "BowlbyOneSC", fontSize: isMobile ? "5vw" : "2vw" }}>Detalhes do Pedido</p>
            <Paper
                elevation={5}
                sx={{
                    flexDirection: "column",
                    borderRadius: isMobile ? "4.5vw" : "2vw",
                    gap: isMobile ? "5vw" : "1vw",
                    padding: isMobile ? "6vw" : "2vw",
                    color: "#686868",
                }}
            >
                <Grid container spacing={6} justifyContent="space-between">
                    <Grid item xs={6}>
                        <p style={{ fontWeight: "bold" }}>Produtos</p>
                    </Grid>
                    <Grid item xs={6} style={{ textAlign: "right" }}>
                        <p style={{ fontWeight: "bold" }}>Subtotal</p>
                    </Grid>
                </Grid>
                {cart.products.map((product) => (
                    <Grid container spacing={6} key={product.id}>
                        <Grid item xs={6}>
                            <p>{product.name}</p>
                        </Grid>
                        <Grid item xs={6} style={{ textAlign: "right" }}>
                            <CurrencyText value={product.price * product.quantity} />
                        </Grid>
                    </Grid>
                ))}
                <Grid container spacing={6}>
                    <Grid item xs={6}>
                        <p style={{ fontWeight: "bold" }}>Subtotal</p>
                    </Grid>
                    <Grid item xs={6} style={{ textAlign: "right" }}>
                        <CurrencyText value={cart.total} style={{ fontWeight: "bold" }} />
                    </Grid>
                </Grid>
                <Grid container spacing={6}>
                    <Grid item xs={6}>
                        <p style={{ fontWeight: "bold" }}>Frete</p>
                    </Grid>
                    <Grid item xs={6} style={{ textAlign: "right" }}>
                        <CurrencyText value={shipping_price} style={{ fontWeight: "bold" }} />
                    </Grid>
                </Grid>
                {coupon_value && (
                    <Grid container spacing={6}>
                        <Grid item xs={6}>
                            <p style={{ fontWeight: "bold" }}>Cupom ({coupon?.code})</p>
                        </Grid>
                        <Grid item xs={6} style={{ textAlign: "right" }}>
                            <CurrencyText value={coupon_value * -1} style={{ fontWeight: "bold" }} />
                        </Grid>
                    </Grid>
                )}
                <Box sx={{ borderTop: "1px solid", borderColor: "primary.main", color: "black", paddingTop: isMobile ? "5vw" : "2vw" }}>
                    <Grid container spacing={6}>
                        <Grid item xs={6}>
                            <p style={{ fontWeight: "bold" }}>Total</p>
                        </Grid>
                        <Grid item xs={6} style={{ textAlign: "right" }}>
                            <CurrencyText value={total < 0 ? 0 : total} style={{ fontWeight: "bold" }} />
                        </Grid>
                    </Grid>
                </Box>
            </Paper>
            {!isMobile && (
                <ButtonComponent onClick={() => handleSubmit()} id="buy-button">
                    {makingOrder ? <CircularProgress size="1.5rem" color="secondary" /> : "Pagar"}
                </ButtonComponent>
            )}
        </Box>
    )
}
