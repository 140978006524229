import React, { useEffect, useState } from "react"
import { Box, CircularProgress, Paper, TextField, useMediaQuery } from "@mui/material"
import { Coupon } from "../../types/server/class/Coupon"
import { useFormik } from "formik"
import { useUser } from "../../hooks/useUser"
import { ButtonComponent } from "../ButtonComponent"
import { api } from "../../api"

interface CouponProps {
    onSuccess: (coupon: Coupon | null) => void
    total: number
    coupon: Coupon | null
}

export const CouponInput: React.FC<CouponProps> = ({ onSuccess, total, coupon }) => {
    const isMobile = useMediaQuery("(orientation: portrait)")
    const { user } = useUser()

    const [helperText, setHelperText] = useState("")
    const [error, setError] = useState(false)
    const [loading, setLoading] = useState(false)

    const formik = useFormik<{ code: string; user_id?: number }>({
        initialValues: {
            code: "",
            user_id: user?.id,
        },
        async onSubmit(values, formikHelpers) {
            if (loading) return

            try {
                setHelperText("")
                setLoading(true)
                const response = await api.post("/coupon/try", values)

                if (!response.data) {
                    setHelperText("Nenhum cupom encontrado ou disponível")
                    setError(true)
                } else {
                    const coupon = response.data as Coupon
                    if (coupon.min_value && total < coupon.min_value) {
                        setHelperText(`Valor mínimo do cupom: R$ ${coupon.min_value.toFixed(2)}`)
                        setError(true)
                        return
                    }

                    onSuccess(coupon)
                    setHelperText(`Cupom de ${coupon.is_percent ? "" : "R$"} ${coupon.value} ${coupon.is_percent ? "%" : ""} aplicado`)
                }
            } catch (error) {
                console.log(error)
            } finally {
                setLoading(false)
            }
        },
        enableReinitialize: true,
    })

    useEffect(() => {
        if (helperText) {
            setHelperText("")
            setError(false)
            onSuccess(null)
        }
    }, [formik.values.code])

    return (
        <Box sx={{ flexDirection: "column", color: "primary.main", gap: isMobile ? "5vw" : "2vw", width: "100%" }}>
            <p style={{ fontFamily: "BowlbyOneSC", fontSize: isMobile ? "5vw" : "2vw" }}>Cupom</p>
            <Paper
                elevation={5}
                sx={{
                    padding: isMobile ? "4vw" : "2vw",
                    flexDirection: "column",
                    borderRadius: isMobile ? "4.5vw" : "2vw",
                    gap: "1vw",
                }}
            >
                <form onSubmit={formik.handleSubmit} style={{ display: "contents" }}>
                    <Box sx={{ gap: "1vw" }}>
                        <TextField
                            label="Código do cupom"
                            value={formik.values.code.toLocaleUpperCase("pt-br")}
                            name="code"
                            onChange={formik.handleChange}
                            InputProps={{ sx: { bgcolor: "#F0EEEE" } }}
                            required
                            onBlur={formik.handleBlur}
                            FormHelperTextProps={{ sx: { color: "success.main" } }}
                        />
                        <ButtonComponent disabled={!!coupon && !error} type="submit">
                            {loading ? <CircularProgress size="1.75rem" color="secondary" /> : "Aplicar"}
                        </ButtonComponent>
                    </Box>
                    {!!helperText && (error || coupon) && (
                        <Box sx={{ color: (error ? "error" : "success") + ".main", fontWeight: "bold", fontSize: "1.2rem" }}>{helperText}</Box>
                    )}
                </form>
            </Paper>
        </Box>
    )
}
