import React from "react"
import { Avatar, Box, Skeleton, Paper, Grid, Button, useMediaQuery, colors, SxProps } from "@mui/material"
import { useNavigate } from "react-router-dom"
import { useCart } from "../../hooks/useCart"
import { useDynamicImage } from "../../hooks/useDynamicImage"
import { CurrencyText } from "../../components/CurrencyText"
import useMeasure from "react-use-measure"
import ImageIcon from "@mui/icons-material/Image"
import { useVisibleCallback } from "burgos-use-visible-callback"
import { Product } from "../../types/server/class/Product"

interface ProductContainerProps {
    product: Product
    onVisible?: () => void
}

export const ProductContainer: React.FC<ProductContainerProps> = ({ product, onVisible }) => {
    const isMobile = useMediaQuery("(orientation: portrait)")
    const navigate = useNavigate()
    const cart = useCart()
    const [ref, dimensions] = useMeasure()
    const productRef = useDynamicImage(product)
    const onVisibleRef = useVisibleCallback(() => {
        if (onVisible) onVisible()
    }, {})
    const soldout = Number(product.stock) < 1 && product.dropshipping < 1

    const image_skeleton_style = {
        height: isMobile ? "40vw" : "12vw",
        width: isMobile ? "40vw" : "23vw",
        borderRadius: isMobile ? "5vw" : "1vw",
    }

    const button_Style: SxProps = {
        borderRadius: isMobile ? "20vw" : "2vw",
        border: "none",
        color: "white",
        justifyContent: "center",
        alignItems: "center",
        fontFamily: "BowlbyOneSC",
        width: isMobile ? "40vw" : "10vw",
    }

    return (
        <Box
            className="results-container"
            style={{
                flexDirection: "row",
                alignItems: "center",
                width: "100%",
                height: "100%",
                gap: isMobile ? "3vw" : "",
                justifyContent: "space-around",
                borderRadius: isMobile ? "5vw" : "2vw",
                padding: isMobile ? "4vw" : "1vw",
                boxShadow: "0 2px 3px rgba(0, 0, 0, 0.4)",
            }}
            key={product.id}
            onClick={() => navigate(`/product/${product.id}`)}
            sx={{ marginBottom: isMobile ? "1vw" : "" }}
            ref={productRef}
        >
            <Box ref={onVisibleRef} sx={{ alignItems: "center", width: "100%", height: "100%", justifyContent: "space-around" }}>
                {product.cover ? (
                    <Avatar
                        src={"data:image/jpeg;base64," + product.cover}
                        variant="square"
                        sx={{
                            width: isMobile ? "40vw" : "50%",
                            height: "auto",
                            borderRadius: isMobile ? "5vw" : "1vw",
                            cursor: "pointer",
                            // maxHeight: isMobile ? "40vw" : "12vw",
                            // aspectRatio: 1,
                            // objectFit: "cover",
                        }}
                    >
                        <ImageIcon sx={{ width: "auto", height: "auto" }} />
                    </Avatar>
                ) : (
                    <Skeleton
                        variant="rounded"
                        animation="wave"
                        sx={{
                            width: isMobile ? "40vw" : "12vw",
                            height: isMobile ? "40vw" : "12vw",
                            flexShrink: 0,
                            borderRadius: isMobile ? "5vw" : "1vw",
                        }}
                    />
                )}
                <Box sx={{ flexDirection: "column", alignItems: "center", height: "100%", justifyContent: "space-between", padding: "0 1vw" }}>
                    <Button sx={{ color: "primary.main", padding: 0 }}>
                        <h4
                            style={{
                                textAlign: "start",
                                whiteSpace: "break-spaces",
                                // overflow: "hidden",
                                // textOverflow: "ellipsis",
                                width: isMobile ? "40vw" : "100%",
                            }}
                        >
                            {product.name}
                        </h4>
                    </Button>
                    <Box sx={{ flexDirection: "column", alignItems: "center" }}>
                        <h2
                            style={{
                                textAlign: "start",
                                fontSize: isMobile ? "5vw" : "1.5rem",
                                textOverflow: "ellipsis",
                                whiteSpace: "nowrap",
                                overflow: "hidden",
                                width: isMobile ? "40vw" : "100%",
                                color: "#363775",
                            }}
                        >
                            <CurrencyText value={product.price} style={{ fontWeight: "bold" }} />
                        </h2>
                    </Box>
                    <Button
                        onClick={() => cart.add(product, 1)}
                        color="success"
                        variant="contained"
                        fullWidth
                        sx={{ ...button_Style }}
                        disabled={soldout}
                    >
                        {soldout ? "Indisponível" : "Quero esse"}
                    </Button>
                </Box>
            </Box>
        </Box>
    )
}
