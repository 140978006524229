import React from "react"
import { Box, useMediaQuery } from "@mui/material"
import { DefaultWrapper } from "../../components/DefaultWrapper"

interface NotFranchiseProps {}

export const NotAvailable: React.FC<NotFranchiseProps> = ({}) => {
    const isMobile = useMediaQuery("(orientation: portrait)")
    return (
        <DefaultWrapper>
            <Box sx={{ paddingTop: isMobile ? "30vw" : "13vw", paddingBottom: "10vw", color: "primary.main" }}>
                <h3>
                    HMM, INFELIZMENTE NOSSO SITE NÃO ESTÁ DISPONIVEL PARA SUA REGIÃO NO MOMENTO, ENTRE EM CONTATO VIA WHATSAPP PARA SOLICITAR UM
                    ORÇAMENTO.
                </h3>
            </Box>
        </DefaultWrapper>
    )
}
