import React from "react"
import { Box, Skeleton, useMediaQuery } from "@mui/material"

interface ProductSkeletonProps {}

export const ProductSkeleton: React.FC<ProductSkeletonProps> = ({}) => {
    const isMobile = useMediaQuery("(orientation: portrait)")

    const skeleton_style = {
        height: isMobile ? "8vw" : "1vw",
        width: isMobile ? "100%" : "100%",
    }

    const skeleton_image_style = {
        height: isMobile ? "40vw" : "12vw",
        width: isMobile ? "40vw" : "12vw",
        borderRadius: isMobile ? "5vw" : "1vw",
        flexShrink: 0,
    }

    return (
        <Box
            sx={{
                flexDirection: "row",
                alignItems: "center",
                width: "100%",
                height: "100%",
                gap: isMobile ? "3vw" : "1vw",
                justifyContent: "space-around",
                borderRadius: isMobile ? "5vw" : "2vw",
                padding: isMobile ? "4vw" : "1vw",
                boxShadow: "0 2px 3px rgba(0, 0, 0, 0.4)",
            }}
        >
            <Skeleton animation="wave" variant="rounded" sx={skeleton_image_style} />
            <Box sx={{ width: isMobile ? "45%" : "100%", flexDirection: "column", gap: isMobile ? "5vw" : "2vw" }}>
                <Skeleton animation="wave" variant="rounded" sx={{ ...skeleton_style, height: isMobile ? "5vw" : "1.5vw" }} />
                <Skeleton animation="wave" variant="rounded" sx={{ ...skeleton_style, height: isMobile ? "7vw" : "2.2vw" }} />
                <Skeleton animation="wave" variant="rounded" sx={{ ...skeleton_style, height: isMobile ? "9vw" : "2.4vw", borderRadius: "5vw" }} />
            </Box>
        </Box>
    )
}
