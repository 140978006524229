import { Skeleton, Box, useMediaQuery, Grid, Button } from "@mui/material"
import React, { useEffect, useState } from "react"
import { ProductComponent as Container } from "./Product"
import { useColors } from "../../../hooks/useColors"
import { useFranchise } from "../../../hooks/useFranchise"
import kids_image from "../../../images/featured/kids.svg"
import { useNavigate } from "react-router-dom"
import { Product } from "../../../types/server/class/Product"
import { api } from "../../../api"
//import "./style.scss"

interface PopularProps {}

export const Popular: React.FC<PopularProps> = ({}) => {
    const isMobile = useMediaQuery("(orientation: portrait)")
    const colors = useColors()
    const navigate = useNavigate()
    const { franchise } = useFranchise()

    const [products, setProducts] = useState<Product[]>([])

    const fetchProducts = async () => {
        if (!franchise) return
        try {
            const response = await api.get("/products", {
                params: {
                    store_id: franchise.id,
                    search_type: "popular",
                    take: 6,
                    skip: 0,
                },
            })
            setProducts(response.data)
        } catch (error) {
            console.log(error)
        }
    }

    useEffect(() => {
        fetchProducts()
    }, [franchise])

    return (
        <Box
            className="Popular-Component"
            sx={{
                width: " 100%",
                flexDirection: isMobile ? "column" : "row",
                color: colors.primary,
                gap: isMobile ? "1vw" : "0",
            }}
        >
            {isMobile && (
                <>
                    <h3 style={{ fontSize: "5vw" }}>Mais curtidos pela galerinha</h3>
                    <Box
                        className="products-container"
                        sx={{ width: "100vw", marginLeft: "-5vw", padding: "1vw 5vw", gap: "2vw", overflowX: "auto", scrollbarWidth: "none" }}
                    >
                        {products.map((product) => (
                            <Container key={product.id} product={product} />
                        ))}

                        {products.length == 0 && (
                            <>
                                <Skeleton animation="wave" sx={{ flexShrink: 0 }} variant="rounded" width={"40vw"} height={"50vw"} />
                                <Skeleton animation="wave" sx={{ flexShrink: 0 }} variant="rounded" width={"40vw"} height={"50vw"} />
                                <Skeleton animation="wave" sx={{ flexShrink: 0 }} variant="rounded" width={"40vw"} height={"50vw"} />
                            </>
                        )}
                    </Box>
                </>
            )}
            {!isMobile && (
                <Grid container spacing={2} columns={2}>
                    <Grid item xs={2}>
                        <Box sx={{ justifyContent: "space-between", alignItems: "center" }}>
                            <Box sx={{ height: "100%", alignItems: "center", width: "100%" }}>
                                <h3 style={{ fontSize: "2.5rem" }}>
                                    Mais curtidos
                                    <br />
                                    pela galerinha
                                </h3>
                                <img src={kids_image} style={{ width: "6vw", margin: "auto" }} />
                            </Box>
                            <Box sx={{ gap: isMobile ? "0.5vw" : "1vw" }}>
                                {products.slice(0, 3).map((product) => (
                                    <Container product={product} key={product.id} />
                                ))}

                                {products.length == 0 && (
                                    <Box sx={{ gap: "1vw", padding: "1vw" }}>
                                        <Grid item>
                                            <Skeleton animation="wave" sx={{ flexShrink: 0 }} variant="rounded" width={"12vw"} height={"15vw"} />
                                        </Grid>
                                        <Grid item>
                                            <Skeleton animation="wave" sx={{ flexShrink: 0 }} variant="rounded" width={"12vw"} height={"15vw"} />
                                        </Grid>
                                        <Grid item>
                                            <Skeleton animation="wave" sx={{ flexShrink: 0 }} variant="rounded" width={"12vw"} height={"15vw"} />
                                        </Grid>
                                    </Box>
                                )}
                            </Box>
                        </Box>
                    </Grid>
                    <Grid item xs={2}>
                        <Box
                            sx={{
                                flexDirection: "row",
                                // alignItems: "flex-end",
                                justifyContent: "space-between",
                                height: "100%",
                            }}
                        >
                            <Grid item xs={1}>
                                <Box sx={{ gap: "1vw" }}>
                                    {products.slice(3, 6).map((product) => (
                                        <Container product={product} key={product.id} />
                                    ))}
                                    {products.length == 0 && (
                                        <Box sx={{ gap: "1vw", padding: "1vw" }}>
                                            <Grid item>
                                                <Skeleton animation="wave" sx={{ flexShrink: 0 }} variant="rounded" width={"12vw"} height={"15vw"} />
                                            </Grid>
                                            <Grid item>
                                                <Skeleton animation="wave" sx={{ flexShrink: 0 }} variant="rounded" width={"12vw"} height={"15vw"} />
                                            </Grid>
                                            <Grid item>
                                                <Skeleton animation="wave" sx={{ flexShrink: 0 }} variant="rounded" width={"12vw"} height={"15vw"} />
                                            </Grid>
                                        </Box>
                                    )}
                                </Box>
                            </Grid>
                            <Box
                                sx={{
                                    flexDirection: "column",
                                    alignItems: "flex-end",
                                    justifyContent: "center",
                                    height: "100%",
                                }}
                            >
                                <h3 style={{ fontSize: "2.5rem", textAlign: "end" }}>Não fique de fora!</h3>
                                <Button
                                    sx={{
                                        fontSize: "2.5rem",
                                        textDecoration: "underline",
                                        pt: 0,
                                        pb: 0,
                                        fontFamily: "BowlbyOneSC",
                                        textTransform: "unset",
                                        "&:hover": {
                                            textDecoration: "underline",
                                        },
                                    }}
                                    onClick={() => navigate("/search/name/")}
                                >
                                    Veja mais
                                </Button>
                            </Box>
                        </Box>
                    </Grid>
                </Grid>
            )}
        </Box>
    )
}
